import React, { useEffect, useState, useRef } from "react"
import { DataGrid } from "@mui/x-data-grid"
import PropTypes from "prop-types"
import ClearIcon from "@material-ui/icons/Clear"
import SearchIcon from "@material-ui/icons/Search"
import { makeStyles, IconButton, TextField } from "@material-ui/core"
import { Button } from "../../../../../_partials/Button"
import { UserGrouping } from "../UserGrouping.js/UserGrouping"
import { TiInputChecked } from "react-icons/ti"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { SearchBarCustom1 } from "../../../../../_partials/inputs/SearchBarCustom1"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const AnchorsListDragTable = ({
	anchorsPositionData,
	map,
	drawerListMarkers,
	setDrawerListMarkers,
	handleDrawerListMarkerPositionChange,
	modelsInventory
}) => {
	const classes = useStyles()

	//━━━━━━━━━━━━━━ States ━━━━━━━━━━━━━━\\
	const [initialData, setInitialData] = useState(null)
	const [searchText, setSearchText] = useState(null)
	const [searchedRows, setSearchedRows] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	//━━━━━━━━━━━━━━ useEffects ━━━━━━━━━━━━━━\\
	//filters anchors data by created ones only and sets initial data state
	useEffect(() => {
		if (!anchorsPositionData) return

		setIsLoading(false)

		setInitialData(anchorsPositionData.filter(val => val.status === "assigned")) // set an initial Data with fetched data so that when the user clears the search input the data of the table returns to the initialData
	}, [anchorsPositionData])

	//search box search logic
	useEffect(() => {
		// Filter the data based on searchText if it is not null or empty string
		if (searchText && searchText.trim() !== "") {
			const filteredRows = initialData.filter(row => {
				const { uuid, modelId } = row

				// Perform your filtering logic here based on the columns you want to search
				const matchFound =
					uuid?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
					modelId?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
					null

				return matchFound
			})

			setSearchedRows(filteredRows)
		} else {
			setSearchedRows(null)
		}
	}, [searchText, initialData])

	// Inside your AnchorsListDragTable component

	// useEffect(() => {
	// 	const mapContainer = document.getElementById("mapbox-anchors-container")

	// 	const handleDrop = event => {
	// 		event.preventDefault()

	// 		// Get the dropped item data, such as coordinates, from the event
	// 		const droppedData = event.dataTransfer.getData("text")

	// 		// Process the dropped data as needed
	// 		// console.log("Dropped data:", droppedData)
	// 	}

	// 	const handleDragOver = event => {
	// 		event.preventDefault()
	// 	}

	// 	const handleTouchStart = event => {
	// 		event.preventDefault()
	// 		// Your touch start logic here
	// 	}

	// 	const handleTouchMove = event => {
	// 		event.preventDefault()
	// 		// Your touch move logic here
	// 	}

	// 	const handleTouchEnd = event => {
	// 		event.preventDefault()
	// 		// Your touch end logic here
	// 	}

	// 	// Attach the drop event listener to the map container
	// 	mapContainer.addEventListener("drop", handleDrop)

	// 	// Prevent the default behavior for dragover events to enable dropping
	// 	mapContainer.addEventListener("dragover", handleDragOver)
	// 	mapContainer.addEventListener("touchstart", handleTouchStart)
	// 	mapContainer.addEventListener("touchmove", handleTouchMove)
	// 	mapContainer.addEventListener("touchend", handleTouchEnd)

	// 	// Cleanup function to remove event listeners when the component unmounts
	// 	return () => {
	// 		mapContainer.removeEventListener("drop", handleDrop)
	// 		mapContainer.removeEventListener("dragover", handleDragOver)
	// 		mapContainer.removeEventListener("touchstart", handleTouchStart)
	// 		mapContainer.removeEventListener("touchmove", handleTouchMove)
	// 		mapContainer.removeEventListener("touchend", handleTouchEnd)
	// 	}
	// }, []) // Ensure this effect runs only once after component mount

	//━━━━━━━━━━━━━━ useEffects ━━━━━━━━━━━━━━\\
	const handleSearch = value => {
		setSearchText(value)
	}

	const columns = [
		{
			field: "draggableIcon",
			width: 25,
			renderHeader: () => (
				<img
					src="/markers/anchor.svg"
					id="marker-img"
					alt="Mapbox Marker"
					style={{
						width: "55%",
						height: "55%",
						pointerEvents: "none",
						filter: "invert(100%)",
						justifyContent: "center"
					}}
				/>
			),
			renderCell: (
				params,
				map,
				drawerListMarkers,
				setDrawerListMarkers,
				handleDrawerListMarkerPositionChange,
				setInitialData
			) => {
				const rowData = params.row
				const markerId = rowData.uuid
				const iconElementRef = useRef(null)
				const [drawerListMarkerToUpdate, setDrawerListMarkerToUpdate] = useState(null)
				const touchData = {}

				useEffect(() => {
					if (!drawerListMarkerToUpdate) return
					handleDrawerListMarkerPositionChange(drawerListMarkerToUpdate)
					setIsLoading(true)
				}, [drawerListMarkerToUpdate])

				const handleTouchStart = (e, markerId) => {
					e.preventDefault()
					const touch = e.touches[0]

					// Store initial touch position
					const initialX = touch.clientX
					const initialY = touch.clientY

					// Store initial marker position
					const markerElement = document.getElementById(`div-${markerId}`)
					if (!markerElement) return
					const markerRect = markerElement.getBoundingClientRect()
					const initialMarkerX = markerRect.left + window.pageXOffset
					const initialMarkerY = markerRect.top + window.pageYOffset

					// Attach data to touch event for use in move handler
					touchData[markerId] = {
						initialX,
						initialY,
						initialMarkerX,
						initialMarkerY
					}
				}

				const handleTouchMove = (e, markerId) => {
					e.preventDefault()
					const touch = e.touches[0]
					const { initialX, initialY, initialMarkerX, initialMarkerY } =
						touchData[markerId] || {}

					if (initialX === undefined || initialY === undefined) return

					// Calculate new position
					const deltaX = touch.clientX - initialX
					const deltaY = touch.clientY - initialY

					const markerElement = document.getElementById(`div-${markerId}`)
					if (!markerElement) return

					markerElement.style.transform = `translate(${deltaX}px, ${deltaY}px)`
				}

				const handleTouchEnd = (e, markerId) => {
					// Prevent default behavior, useful on touch devices
					e.preventDefault()

					// Handle drag end with touch event specifics
					handleOnDragEnd(e, markerId)
				}

				const handleOnDragEnd = e => {
					const userAgent = navigator.userAgent
					const markerId = rowData.uuid
					const iconElement = document.getElementById(`div-${markerId}`)
					const markerImg = document.getElementById("marker-img")
					const mapContainer = document.getElementById("mapbox-anchors-container") // Replace "your-map-container-id" with the actual ID of your Mapbox container

					const mapContainerRect = mapContainer.getBoundingClientRect()
					const mapContainerX = mapContainerRect.left
					const mapContainerY = mapContainerRect.top

					// Calculate the position relative to the map container
					// const xRelativeToContainer = e.clientX - mapContainerX
					// const yRelativeToContainer = e.clientY - mapContainerY

					let x, y

					// Detect if the event is a touch event
					if (e.touches && e.touches.length > 0) {
						// Touch event (dragging)
						x = e.touches[0].clientX
						y = e.touches[0].clientY
					} else if (e.changedTouches && e.changedTouches.length > 0) {
						// Touch event (on touchend)
						x = e.changedTouches[0].clientX
						y = e.changedTouches[0].clientY
					} else {
						// Mouse event
						x = e.clientX
						y = e.clientY
					}

					const xRelativeToContainer = x - mapContainerX
					const yRelativeToContainer = y - mapContainerY

					// Calculate the marker position based on the container position and the marker image dimensions
					const markerX = xRelativeToContainer - markerImg.width / 2
					const markerY = yRelativeToContainer - markerImg.height / 2

					// Convert the marker position to map coordinates
					const mapCoordinates = map.unproject([markerX, markerY])

					// Check if a marker with the same ID already exists
					const existingMarker =
						drawerListMarkers &&
						drawerListMarkers.find(marker => marker.getElement().id === `marker-${markerId}`)

					if (!existingMarker) {
						// Create a new marker at the clicked position
						const newMarker = new mapboxgl.Marker({
							draggable: false,
							element: createCustomMarker()
						}).setLngLat([mapCoordinates?.lng, mapCoordinates?.lat])
						// .addTo(map)

						// Set the marker's ID to markerId
						newMarker.getElement().id = `marker-${markerId}`

						// Add the new marker to the markers state
						let markerToUpdate = {
							uuid: markerId,
							longitude: mapCoordinates?.lng,
							latitude: mapCoordinates?.lat,
							status: "planned"
						}
						setDrawerListMarkerToUpdate(markerToUpdate)
						setInitialData(prevData => prevData.filter(item => item.uuid !== markerId))
					}
				}

				function createCustomMarker(color) {
					const markerContainer = document.createElement("div")

					const anchorDiv = document.createElement("div")
					anchorDiv.className = "anchorMarkersDesignStudio_new"
					anchorDiv.style.cursor = "pointer"
					anchorDiv.style.display = "flex"
					anchorDiv.style.justifyContent = "center"
					anchorDiv.style.alignItems = "center"

					const img = document.createElement("img")
					img.src = "/markers/anchor.svg"
					img.alt = "Mapbox Marker"
					img.style.width = "55%"
					img.style.height = "55%"

					// Append the image to the anchor div
					anchorDiv.appendChild(img)

					// Append the anchor div to the marker container
					markerContainer.appendChild(anchorDiv)

					return markerContainer
				}

				return (
					<div
						className="anchors_page_marker_divs"
						style={{
							boxShadow: "0px 4px 14px 0px rgba(27, 27, 27, 0.15)",
							borderRadius: "50%",
							backgroundColor: "#8C8CA1",
							border: "1px solid white",
							// borderRadius: "4px",
							// backgroundColor: "#ff914f",
							flexShrink: 0,
							cursor: "pointer",
							pointerEvents: isLoading ? "none" : "auto",
							zIndex: "10000"
						}}
						id={`div-${markerId}`}
						// onClick={onClick}
						// onDoubleClick={onDoubleClick}
						ref={iconElementRef}
						draggable={true}
						// onDragStart={e => console.log("e, rowData", e, rowData)}
						onDragEnd={e => {
							handleOnDragEnd(e)
							// console.log("testing drag preview")
							// e.preventDefault()
						}}
						onTouchStart={e => handleTouchStart(e, markerId)}
						onTouchMove={e => handleTouchMove(e, markerId)}
						onTouchEnd={e => handleTouchEnd(e, markerId)}
					>
						<img
							src="/markers/anchor.svg"
							id="marker-img"
							alt="Mapbox Marker"
							style={{
								width: "55%",
								height: "55%",
								pointerEvents: "none"
							}}

							// style={{ width: "100%", height: "100%", background: "#000" }}
						/>
					</div>
				)
			}
		},
		{ field: "uuid", headerName: "UUID", width: 100 },
		{
			field: "model",
			headerName: "Model",
			type: "string",
			minWidth: 165,
			renderCell: params => {
				const { modelId } = params.row

				const dataForTooltip =
					modelsInventory &&
					modelsInventory.length > 0 &&
					modelsInventory.find(m => m.id === modelId)

				if (dataForTooltip) {
					const { vendor, name, id } = dataForTooltip

					const text = `${name} (${vendor})`

					return (
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip id="firebase-notification-tooltip">{id}</Tooltip>}
						>
							<span>{text}</span>
						</OverlayTrigger>
					)
				} else {
					return <span>{modelId}</span>
				}
			}
		}
	]

	return (
		<>
			<div className="how-to-info">
				<span>Click and drag to place an anchor on the map</span>
			</div>
			<div style={{ padding: "0.5rem 1rem" }}>
				<SearchBarCustom1
					disabled={isLoading}
					onSearch={handleSearch}
					searchText={searchText}
					// disabled={listLoading || !technology ? true : false}
					placeholder="Search"
					size="small"
					style={{
						background: "#F2F2F2"
					}}
					InputProps={{
						startAdornment: (
							<SearchIcon
								fontSize="small"
								style={{
									color: "#7F8C8D",
									marginRight: "0.7rem"
								}}
							/>
						)
					}}
				/>
			</div>
			<div className={classes.table}>
				<DataGrid
					rows={searchedRows || initialData || []}
					loading={isLoading}
					pageSize={(initialData && initialData.length) || 0}
					// columns={columns}
					columns={columns.map(column => {
						if (column.field === "draggableIcon") {
							return {
								...column,
								renderCell: params =>
									column.renderCell(
										params,
										map,
										drawerListMarkers,
										setDrawerListMarkers,
										handleDrawerListMarkerPositionChange,
										setInitialData
									)
							}
						}
						return column
					})}
					checkboxSelection={false}
					disableColumnMenu
					hideFooterPagination={true}
					autoHeight={true}
					// headerHeight={100}
					density="compact"
					hideFooterSelectedRowCount={true}
					sx={{
						".MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell": {
							overflow: "visible"
						},
						".MuiDataGrid-virtualScroller": {
							position: "inherit"
						}
					}}
				/>
			</div>
		</>
	)
}

export default AnchorsListDragTable

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1, 1, 1, 1),
		justifyContent: "space-between",
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		// filters and densitiy icons color
		"& .MuiButton-textPrimary": {
			color: "#192942"
		}
	},
	table: {
		width: "100%",
		padding: "0 1rem",
		"& .MuiDataGrid-root": {
			border: "none" // Remove the border
		},
		"& .MuiDataGrid-main": {
			overflow: "visible"
		},
		"& .MuiTablePagination-toolbar": {
			position: "static"
		},
		"& .MuiDataGrid-window": {
			overflowY: "visible !important"
		},
		"& .MuiDataGrid-columnSeparator": {
			display: "none"
		},
		"& .MuiDataGrid-columnHeaderTitle": {
			color: "#4A4A68",
			fontFamily: "Poppins",
			fontSize: "13px",
			fontStyle: "normal",
			fontWeight: "600"
			// lineHeight: "32px"
		}
	},
	textField: {
		[theme.breakpoints.down("xs")]: {
			width: "100%"
		},
		margin: theme.spacing(1, 0.5, 1.5),
		"& .MuiSvgIcon-root": {
			marginRight: theme.spacing(0.5)
		},
		// "& .MuiInput-underline:before": {
		//   borderBottom: `1px solid ${theme.palette.divider}`,
		// },
		backgroundColor: "#ecf1f4",
		"& .MuiSelect-root": {
			minWidth: "8rem"
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#ecf1f4"
			}
		},
		"&.Mui-focused fieldset": {
			borderColor: "#192942"
		}
	}
}))

function QuickSearchToolbar(props) {
	const { selectedRows, initialData, setShowDeleteSeveral, setShowApproveModal } = props
	const classes = useStyles()

	// const placedRows = initialData && initialData.filter(d => d.placedOnSite === "Placed")
	const placedRows = initialData && initialData.filter(d => d.status === "placed")

	const foundedPlacedRows =
		selectedRows && selectedRows.some(r => placedRows && placedRows.find(val => val.id === r))

	return (
		<div className={classes.root}>
			<div className="d-flex align-items-center">
				{/* <GridToolbarFilterButton /> */}
				{/* <GridToolbarDensitySelector /> */}
				<UserGrouping selectedRows={selectedRows} setShowDeleteSeveral={setShowDeleteSeveral} />

				<div className="ml-4">
					<Button
						disabled={!foundedPlacedRows}
						onClick={() => setShowApproveModal(true)}
						variant="contained"
						color="primary"
						text="Approve"
						endIcon={<TiInputChecked />}
					/>
				</div>
			</div>
			<div className="d-flex align-items-center">
				<TextField
					size="small"
					variant="outlined"
					value={props.value}
					onChange={props.onChange}
					placeholder="Search…"
					className={classes.textField}
					InputProps={{
						startAdornment: (
							<SearchIcon
								fontSize="small"
								style={{
									color: "#7F8C8D",
									marginRight: "0.7rem"
								}}
							/>
						),
						endAdornment: (
							<IconButton
								title="Clear"
								aria-label="Clear"
								size="small"
								style={{ visibility: props.value ? "visible" : "hidden" }}
								onClick={props.clearSearch}
							>
								<ClearIcon fontSize="small" />
							</IconButton>
						)
					}}
				/>
			</div>
		</div>
	)
}

QuickSearchToolbar.propTypes = {
	clearSearch: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired
}

function escapeRegExp(value) {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
}
