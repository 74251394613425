import React from "react"
import "./Header.css"
import HeaderDropdownOnMobile from "./HeaderDropdownOnMobile"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers"
import { useLocation } from "react-router"

import { NavLink } from "react-router-dom"

export function Header() {
	const location = useLocation()

	const getMenuItemActive = url => {
		return checkIsActive(location, url) ? true : false
	}

	return (
		<div style={{ marginBottom: "30px" }}>
			<div className="row justify-content-center">
				{/* header title */}
				<div className="col d-flex align-items-center">
					<div
						style={{
							width: "30px",
							height: "30px",
							marginRight: "10px",
							marginBottom: "10px",
							alignContent: "center"
						}}
					>
						<SVG
							src={toAbsoluteUrl("/media/svg/icons/General/operations-icon.svg")}
							style={{
								height: "2rem",
								width: "2rem",
								fill: "#3077d3"
							}}
						/>
					</div>

					<h2 style={{ color: "#4A4A68" }}>
						<b>Operations</b>
					</h2>
				</div>

				{/* desktop menu */}
				<div className="col-sm d-none d-sm-flex justify-content-end">
					<div className="row">
						{/* inventory */}
						<div
							className="col-sm"
							style={{
								textAlign: "center",
								borderBottom: getMenuItemActive("/operations/inventory")
									? "4px solid #2673F0"
									: "none",
								padding: "10px"
							}}
						>
							<NavLink className="operations-menu-link" to="/operations/inventory">
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/General/Inventory-icon.svg")}
									style={{
										height: "2rem",
										width: "2rem",
										fill: getMenuItemActive("/operations/inventory")
											? "#2673F0"
											: "#4A4A68",
										marginBottom: "5px"
									}}
								/>
								<span
									className="header-menu-text"
									style={{
										color: getMenuItemActive("/operations/inventory")
											? "#2673F0"
											: "#4A4A68"
									}}
								>
									Inventory
								</span>
							</NavLink>
						</div>
						{/* manage infra */}
						<div
							className="col-sm"
							style={{
								textAlign: "center",
								borderBottom: getMenuItemActive("/operations/manage-infrastructure")
									? "4px solid #2673F0"
									: "none",
								padding: "10px"
							}}
						>
							<NavLink
								className="operations-menu-link"
								to="/operations/manage-infrastructure"
							>
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/General/manage-infra-icon.svg")}
									style={{
										height: "2rem",
										width: "2rem",
										fill: getMenuItemActive("/operations/manage-infrastructure")
											? "#2673F0"
											: "#4A4A68",
										marginBottom: "5px"
									}}
								/>
								<br />
								<span
									className="header-menu-text"
									style={{
										color: getMenuItemActive("/operations/manage-infrastructure")
											? "#2673F0"
											: "#4A4A68"
									}}
								>
									Map Infra
								</span>
							</NavLink>
						</div>
					</div>
				</div>

				{/* mobile menu */}
				<div className="d-flex d-sm-none justify-content-center m-5">
					<div className="header_dropdown_mobile_view">
						<HeaderDropdownOnMobile />
					</div>
				</div>
			</div>
		</div>
	)
}
